import { motion } from 'framer-motion';

import styles from './Overlay.module.scss';

const Overlay = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.overlay}
    />
  );
};

export default Overlay;
