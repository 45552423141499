import { createContext, ReactNode, useContext, useState } from 'react';

interface SectionContextType {
  section: string;
  setSection: (section: string) => void;
}

const SectionContext = createContext<SectionContextType | undefined>(undefined)

export const ChartbeatSectionProvider = ({ children }: { children: ReactNode }) => {
  const [section, setSection] = useState<string>("default-section");

  return (
    <SectionContext.Provider value={{ section, setSection }}>
      {children}
    </SectionContext.Provider>
  );
};

export const useChartbeatSectionContext = () => {
  const context = useContext(SectionContext);

  if (!context) {
    throw new Error("useSection must be used within a SectionProvider");
  }

  return context;
};