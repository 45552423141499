import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import LogoutIcon from '~/icons/LogoutIcon';
import Profile from '~/icons/Profile';
import SettingsIcon from '~/icons/SettingsIcon';

import { NavLink } from '@remix-run/react';

import styles from './UserModal.module.scss';

interface Props {
  onLogout: () => Promise<void>;
}

const UserModal: FC<Props> = ({ onLogout }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      <div className={styles.userModal}>
        <NavLink className={styles.dropdownButton} to='/user-profile'>
          <Profile />
          View Profile
        </NavLink>
        <NavLink className={styles.dropdownButton} to='/user-settings'>
          <SettingsIcon />
          Settings
        </NavLink>
        <div className={styles.dropdownBottom}>
          <button className={styles.dropdownButton} onClick={onLogout}>
            <LogoutIcon />
            Log out
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default UserModal;
