import { captureRemixErrorBoundaryError } from '@sentry/remix';
import layoutSyles from 'app/scss/layout/layout.module.scss';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import 'reset-css';
import { ChartbeatSectionProvider } from '~/context/ChartbeatSectionContext';
import { UserProvider } from '~/context/UserContext';
import { authTokenCookie } from '~/cookies.server';
import useCookieConsent from '~/hooks/useCookieConsent';
import '~/scss/global.scss';

import { LinksFunction, LoaderFunction, json } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from '@remix-run/react';

import Header from './components/Header/Header';
import NewsletterModal from './components/NewsletterModal/NewsletterModal';
import Sidebar from './components/Sidebar/Sidebar';
import ToastList from './components/Toast/ToastList';
import { ToastProvider } from './context/ToastContext';
import { getUserFromToken } from './services/userService';
import { MinimalTagResource } from './types';

type TagsLoaderResponse = {
  data: MinimalTagResource[];
};

export const loader: LoaderFunction = async ({ request }) => {
  const cookieHeader = request.headers.get('Cookie');
  const token = await authTokenCookie.parse(cookieHeader);

  let user = null;
  if (token) {
    user = await getUserFromToken(token);
  }

  const headers: HeadersInit = {};

  if (token && token.token) {
    headers['Authorization'] = `Bearer ${token.token}`;
  }

  const tagsResponse: Response = await fetch(
    `${process.env.API_URL}/tags/main-categories`,
    {
      headers,
    },
  );

  const tags: TagsLoaderResponse = await tagsResponse.json();

  const topTagsResponse: Response = await fetch(
    `${process.env.API_URL}/tags/top-discover-tags`,
    {
      headers,
    },
  );

  const topTags: TagsLoaderResponse = await topTagsResponse.json();

  return json({
    topTags: topTags.data,
    tags: tags.data,
    user,
    googleTagToken: process.env.GOOGLE_TAG_TOKEN ?? '',
  });
};

export const links: LinksFunction = () => {
  return [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-touch-icon.png',
    },
    { rel: 'shortcut icon', href: '/favicon/favicon.png' },
    {
      rel: 'mask-icon',
      href: '/favicon/safari-pinned-tab.svg',
      color: '#5bbad5',
    },
  ];
};

export default function Root() {
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const { topTags, tags, user, googleTagToken } =
    useLoaderData<typeof loader>();
  const location = useLocation();

  useEffect(() => {
    if (
      user &&
      !localStorage.getItem('newsletterModalShown') &&
      !user.mailer_lite_groups.length
    ) {
      setShowSubscribeModal(true);
      localStorage.setItem('newsletterModalShown', 'true');
    }
  }, [user]);

  useEffect(() => {
    if (isMenuOpened) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpened]);

  useEffect(() => {
    setIsMenuOpened(false);
  }, [location]);

  useCookieConsent();

  const checkIfTextPage = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const slug = pathSegments[0];
    if (slug === 'privacy-policy') return true;
  };

  return (
    <ChartbeatSectionProvider>
      <html lang='en' className='cc--darkmode'>
        <head>
          <Meta />
          <Links />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <link rel='preconnect' href='https://fonts.googleapis.com' />
          <link rel='preconnect' href='https://fonts.gstatic.com' />
          <link
            href='https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap'
            rel='stylesheet'
          />
          {/* Google tag (gtag.js) */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleTagToken}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${googleTagToken}');`,
            }}
          />
        </head>
        <body>
          <UserProvider initialUser={user}>
            <ToastProvider>
              <Header
                setIsMenuOpened={setIsMenuOpened}
                isMenuOpened={isMenuOpened}
                topTags={topTags}
              />
              <main className={layoutSyles.container}>
                <div className={layoutSyles.mainHolder}>
                  <Sidebar
                    isTextPage={checkIfTextPage()}
                    isMenuOpened={isMenuOpened}
                    mainTags={tags}
                  />
                  <Outlet />
                  <AnimatePresence>
                    {showSubscribeModal && (
                      <NewsletterModal
                        onClose={() => setShowSubscribeModal(false)}
                      />
                    )}
                  </AnimatePresence>
                </div>
              </main>
              <ToastList />
              <ScrollRestoration />
              <Scripts />
            </ToastProvider>
          </UserProvider>
        </body>
      </html>
    </ChartbeatSectionProvider>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  console.error(error);

  if (process.env.NODE_ENV === 'production') {
    captureRemixErrorBoundaryError(error);
  }

  return <div>Something went wrong</div>;
};
